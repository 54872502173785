$(document).ready(function () {
  $(".location i").addClass("brinca-brinca");
  $("body").css("display", "block");
  $("body").addClass("fadeIn");
  // detect navbar position
  var domain = "https://www.elhuertomexican.com/";
  var home_ = domain;
  var about_ = domain + "about-us";
  var menu_ = domain + "menu";
  var specials_ = domain + "specials";
  var catering_ = domain + "catering";
  var parties_ = domain + "parties";
  var jobs_ = domain + "jobs";
  var accessibility_ = domain + "accessibility";
  const pages = [
    { page: "home", file: home_ },
    { page: "about-us", file: about_ },
    { page: "menu", file: menu_ },
    { page: "specials", file: specials_ },
    { page: "catering", file: catering_ },
    { page: "parties", file: parties_ },
    { page: "jobs", file: jobs_ },
    { page: "accessibility", file: accessibility_ },
  ];

  $(
    ".home, .about-us, .menu, .specials, .catering, .parties, .jobs, .accessibility"
  ).each(function () {
    $(this).click(function () {
      var clickedClass = $(this).attr("class").split(" ")[0];
      var pageObj = pages.find(function (page) {
        return page.page === clickedClass;
      });
      if (pageObj) {
        var file = pageObj.file;
        window.location.href = file;
      }
    });
  });

  //Add nav activo
  function agregarClaseNavActivo() {
    var currentPath = window.location.pathname;
    $(".page").each(function () {
      var link = $(this).text().toLowerCase();
      if (currentPath.includes(link)) {
        $(this).addClass("nav_activo");
      }
    });
    if (currentPath.endsWith("/")) {
      $(".home").addClass("nav_activo");
    }
  }
  $(document).ready(function () {
    agregarClaseNavActivo();
  });

  // Add class on scroll to button up
  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 50) {
      $("header").addClass("sticky_header");
      $(".up").addClass("show_btn");
      $(".opn-cls span").addClass("achica");
    } else {
      $("header").removeClass("sticky_header");
      $(".up").removeClass("show_btn");
      $(".opn-cls span").removeClass("achica");
    }
  });

  // Button Up
  var upUp = $(".up");
  upUp.on("click", function () {
    $(window).scrollTop(0);
  });

  // Cuando se hace clic en '.opn-cls-orders'
  $(".opn-cls-orders").on("click", function (event) {
    // Evitar que el clic se propague al documento
    event.stopPropagation();
    // Alternar la clase 'expande' en '.nav-box'
    $(".nav-box").toggleClass("expande");
    $(".opn-cls-orders i").toggleClass("rotate-it");
  });

  // Cuando se hace clic en cualquier lugar del documento
  $(document).on("click", function (event) {
    // Verificar si el clic no ocurrió dentro de '.nav-box' o '.opn-cls-orders'
    if (
      !$(event.target).closest(".nav-box").length &&
      !$(event.target).closest(".opn-cls-orders").length
    ) {
      // Si no ocurrió dentro de ninguno de los elementos, cerrar la '.nav-box'
      $(".nav-box").removeClass("expande");
      $(".opn-cls-orders i").removeClass("rotate-it");
    }
  });

  //Funcion para manipular el navbar en mobile - pantallas chicas
  $(".opn-cls").on("click", function () {
    $("nav ul").toggleClass("open--cls");
    $(".opn-cls span").toggleClass("equis");
  });

  //Funcion para manipular si se da click por fuera del elemento
  $(document).on("click", function (event) {
    // Verificar si el clic fue fuera del menú y si el menú está abierto
    if (
      !$(event.target).closest("nav").length &&
      $("nav ul").hasClass("open--cls")
    ) {
      $("nav ul").removeClass("open--cls");
      $(".opn-cls span").removeClass("equis");
    }
  });

  //Quitar data en url para slider galeria
  var allowedPaths = ["/home", "/about-us", "/"];
  var currentPath = window.location.pathname.replace(".html", "");
  //console.log('Ubicación: ' + currentPath);
  if (allowedPaths.includes(currentPath)) {
    $('[data-fancybox="gallery"]').fancybox({
      hash: false,
    });

    //Function to control the Slider
    $(document).ready(function () {
      var $slides, interval, $selectors, $btns, currentIndex, nextIndex;

      var cycle = function (index) {
        var $currentSlide, $nextSlide, $currentSelector, $nextSelector;

        nextIndex = index !== undefined ? index : nextIndex;

        $currentSlide = $($slides.get(currentIndex));
        $currentSelector = $($selectors.get(currentIndex));

        $nextSlide = $($slides.get(nextIndex));
        $nextSelector = $($selectors.get(nextIndex));

        $currentSlide.removeClass("active").css("z-index", "0");

        $nextSlide.addClass("active").css("z-index", "1");

        $currentSelector.removeClass("current");
        $nextSelector.addClass("current");

        currentIndex =
          index !== undefined
            ? nextIndex
            : currentIndex < $slides.length - 1
            ? currentIndex + 1
            : 0;

        nextIndex = currentIndex + 1 < $slides.length ? currentIndex + 1 : 0;
      };

      $(function () {
        currentIndex = 0;
        nextIndex = 1;

        $slides = $(".slide");
        $selectors = $(".selector");
        $btns = $(".btn");

        $slides.first().addClass("active");
        $selectors.first().addClass("current");

        interval = window.setInterval(cycle, 4000);

        $selectors.on("click", function (e) {
          var target = $selectors.index(e.target);
          if (target !== currentIndex) {
            window.clearInterval(interval);
            cycle(target);
            interval = window.setInterval(cycle, 4000);
          }
        });

        $btns.on("click", function (e) {
          window.clearInterval(interval);
          if ($(e.target).hasClass("prev")) {
            var target =
              currentIndex > 0 ? currentIndex - 1 : $slides.length - 1;
            cycle(target);
          } else if ($(e.target).hasClass("next")) {
            cycle();
          }
          interval = window.setInterval(cycle, 4000);
        });
      });

      var menu = [];
      $(".swiper-slide").each(function (index) {
        menu.push($(this).find(".slide-inner").attr("data-text"));
      });

      var interleaveOffset = 0.5;
      var swiperOptions = {
        loop: true,
        speed: 1000,
        parallax: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        loopAdditionalSlides: 0,
        watchSlidesProgress: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          progress: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              var slideProgress = swiper.slides[i].progress;
              var innerOffset = swiper.width * interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector(".slide-inner").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
            }
          },
          touchStart: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = "";
            }
          },
          setTransition: function (speed) {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + "ms";
              swiper.slides[i].querySelector(".slide-inner").style.transition =
                speed + "ms";
            }
          },
        },
      };

      var swiper = new Swiper(".swiper-container", swiperOptions);

      var sliderBgSetting = $(".slide-bg-image");
      sliderBgSetting.each(function (indx) {
        if ($(this).attr("data-background")) {
          $(this).css(
            "background-image",
            "url(" + $(this).data("background") + ")"
          );
        }
      });
    });
  }

  // Navbar on menu
  function menuTabs() {
    var tabs = $(".menu_tabs");
    var menuDivs = $(".mm");
    menuDivs.hide();
    menuDivs.eq(0).show();
    tabs.eq(0).addClass("colorea");
    tabs.each(function (index) {
      $(this).click(function () {
        menuDivs.hide();
        menuDivs.eq(index).show();
        tabs.removeClass("colorea");
        $(this).addClass("colorea");
      });
    });
  }
  menuTabs();

  // Show modal on home page
  var allowedPaths = ["/home", "/"];
  var currentPath = window.location.pathname.replace(".html", "");
  console.log("Ubicación: " + currentPath);
  if (allowedPaths.includes(currentPath)) {
    //Data scroll to que mande al article.vips
    $(document).ready(function () {
      // Función para hacer scroll hacia el elemento con la clase .vips
      function scrollToVips() {
        var vipsElement = $(".vips");
        if (vipsElement.length > 0) {
          $("html, body").animate(
            {
              scrollTop: vipsElement.offset().top,
            },
            1000
          ); // Puedes ajustar la duración de la animación según tus preferencias
        }
      }
      // Asociar la función al evento de clic en elementos con el atributo data-scroll-to
      $("[data-scroll-to]").on("click", function () {
        scrollToVips();
      });
    });
  }

  // Show modal on home page after 3 seconds
  var allowedPaths = ["/home", "/"];
  var currentPath = window.location.pathname.replace(".html", "");
  // Esperar 2 segundos después de que la página se haya cargado
  setTimeout(function () {
    if (allowedPaths.includes(currentPath)) {
      // Agregar la propiedad visibility: visible al body
      $("body").addClass("no-scroll");
      $(".modal").addClass("show_modal");
      $(".show_modal").addClass("fadeIn");
      // Cerrar el modal
      $(".btn_modal").on("click", function () {
        $(".modal").addClass("animationless");
        $(".wrap").addClass("animationless2");
        $(".show_modal").removeClass("fadeIn");
        $("body").removeClass("no-scroll");
      });
    }
  }, 2000); // 2000 milisegundos (2 segundos)

  //Get Full Year
  $(".year").each(function () {
    var currentDate = new Date();
    var ANHO = currentDate.getFullYear();
    $(this).text(ANHO);
  });

  $(document).ready(function () {
    // Selecciona todas las imágenes en el documento
    $("img").each(function () {
      // Obtén la URL actual de la imagen
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src + "?v=" + new Date().getTime());
    });
  });

  $(document).ready(function () {
    // Selecciona todos los elementos <script> que cargan archivos JavaScript
    $("script[src]").each(function () {
      // Obtén la URL actual del archivo JavaScript
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src.split("?")[0] + "?v=" + new Date().getTime());
    });
  });

  //Location Name
  LOCATION_NAME = "el huerto";
  //Description
  ABOUT_HOME =
    "El Huerto Mexican Restaurant, located at 16522 Keystone Blvd Unit A1, Parker, CO 80134, offers an authentic culinary experience with dishes such as Super Nachos and Beef, Chicken, or Shrimp Fajitas. Our cozy atmosphere is perfect for enjoying a meal with family or celebrating special occasions while savoring authentic Mexican flavors...";
  ABOUT_ABOUT =
    "El Huerto Mexican Restaurant, located at 16522 Keystone Blvd Unit A1, Parker, CO 80134, offers an authentic culinary experience with dishes such as Super Nachos and Beef, Chicken, or Shrimp Fajitas. Our cozy atmosphere is perfect for enjoying a meal with family or celebrating special occasions while savoring authentic Mexican flavors. <br> With fresh and high-quality ingredients, we capture the essence of Mexican cuisine. From Super Nachos to Beef, Chicken, or Shrimp Fajitas, our restaurant offers a wide variety of dishes to delight diners with authentic Mexican flavors.";
  //Address
  ADDRESS = "16522 Keystone Blvd unit a1, Parker, CO 80134";
  ADDRESS_URL = "https://maps.app.goo.gl/rjCh3Y9C92HQjsbe9";
  //Email
  EMAIL = "";
  //Phone
  PHONE_NUMBER = "(303) 805-7229";
  //Hours & Days
  D1 = "mon - fri";
  D2 = "sat";
  D3 = "sun";
  D4 = "";
  H1 = "10:00 a.m. - 9:00 p.m.";
  H2 = "9:00 a.m. - 9:00 p.m.";
  H3 = "9:30 a.m. - 4:00 p.m.";
  H4 = "";
  GOOGLE_DESK =
    "https://www.google.com/search?client=opera&q=El+Huerto+Mexican+Restaurant+keystone&sourceid=opera&ie=UTF-8&oe=UTF-8#lrd=0x876c8f95676d9afd:0xe7acd5dcc171ff8d,3,,,,";
  GOOGLE_MOB =
    "https://www.google.com/search?client=ms-android-attmexico-mx-rvc3&sca_esv=d66abf2c052447ae&sca_upv=1&sxsrf=ACQVn08ZcZmKYXsykhc6hSk1U6eeTb4LNA%3A1712009994397&q=EL%20HUERTO%20MEXICAN%20RESTAURANT&ludocid=16693953062870581133&ibp=gwp%3B0%2C7&lsig=AB86z5V0cuzIfOclN9pA2BGch-ni&kgs=092b75b404852447&shndl=-1&source=sh%2Fx%2Floc%2Fact%2Fm4%2F2#wptab=si:AKbGX_oXOTjHK3vNPxrwAU4tsC2W_rsdJDrrSHpqUAOdbOh1q_L5kIQ-TPvvjbmvn9W_rZrD4Wd7x5TBdfmVy_sMbvfSgFoxBey9IOtixjm8qGDR8qg9Q4Vum1MxBOMEW3Qb63ZDzYpaIVsFgpYgGrqAWr7mNB_aj4bH9pve6zHVolvY-TEd6A0%3D";
  //Orders Online
  PICK_UP =
    "https://www.wedeliverdouglascounty.com/r/1717/restaurants/delivery/Mexican/El-Huerto-Part-Rest-Parker";
  DELIVERY1 =
    "https://www.doordash.com/store/el-huerto-(keystone-blvd)-parker-23953303/?utm_campaign=gpa";
  DELIVERY2 = "";
  DELIVERY3 = "";
  //Iframe
  IFRAME =
    '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12308.932458341736!2d-104.7964915!3d39.5317949!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c8f95676d9afd%3A0xe7acd5dcc171ff8d!2sEL%20HUERTO%20MEXICAN%20RESTAURANT!5e0!3m2!1sen!2smx!4v1707423187959!5m2!1sen!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
  DESC01 = "order your favorite dishes in seconds!";
  DESC02 = "subscribe to <br> our vip club!";
  //Social Media
  FACEBOOK = "";
  INSTAGRAM = "";
  YELP = "";
  TIKTOK = "";
  //Logo
  $("header img").css("cursor", "pointer");
  $("header img").on("click", function () {
    window.location.href = domain;
  });
  //Location - Name
  $(".location_name").each(function () {
    $(this).text(LOCATION_NAME);
  });
  //Location - Address
  $(".address").each(function () {
    $(this).text(ADDRESS);
  });
  $(".address-link").each(function () {
    $(this).click(function () {
      window.open(ADDRESS_URL, "_blank");
    });
  });
  //About Us
  $(".description_home").each(function () {
    $(this).html(ABOUT_HOME);
  });
  $(".description_about").each(function () {
    $(this).html(ABOUT_ABOUT);
  });
  //Phone
  $(".phone").each(function () {
    $(this)
      .text(PHONE_NUMBER)
      .click(function () {
        window.location.href = "tel:" + PHONE_NUMBER;
      });
  });
  // Email
  $(".email").each(function () {
    $(this)
      .text(EMAIL)
      .click(function () {
        window.location.href = "mailto:" + EMAIL;
      });
  });
  //Hours
  $(".d1").each(function () {
    $(this).text(D1);
  });
  $(".h1").each(function () {
    $(this).text(H1);
  });
  $(".d2").each(function () {
    $(this).text(D2);
  });
  $(".h2").each(function () {
    $(this).text(H1);
  });
  $(".d3").each(function () {
    $(this).text(D3);
  });
  $(".h3").each(function () {
    $(this).text(H1);
  });
  $(".d4").each(function () {
    $(this).text(D4);
  });
  $(".h4").each(function () {
    $(this).text(H1);
  });
  //Iframe
  $(".iframe").each(function () {
    $(this).html(IFRAME);
  });
  //Description Modal
  $(".description-01").each(function () {
    $(this).html(DESC01);
  });
  $(".description-02").each(function () {
    $(this).html(DESC02);
  });
  //Google Reviews
  $(".desk-google").each(function () {
    $(this).click(function () {
      window.open(GOOGLE_DESK, "_blank");
    });
  });
  $(".mob-google").each(function () {
    $(this).click(function () {
      window.open(GOOGLE_MOB, "_blank");
    });
  });
  //3rd Party Vendors
  $(".order-pickup").each(function () {
    $(this).click(function () {
      window.open(PICK_UP, "_blank");
    });
  });
  $(".order-delivery1").each(function () {
    $(this).click(function () {
      window.open(DELIVERY1, "_blank");
    });
  });
  $(".order-delivery2").each(function () {
    $(this).click(function () {
      window.open(DELIVERY2, "_blank");
    });
  });
  $(".order-delivery3").each(function () {
    $(this).click(function () {
      window.open(DELIVERY3, "_blank");
    });
  });
  //Social Media
  $(".fb").each(function () {
    $(this).click(function () {
      window.open(FACEBOOK, "_blank");
    });
  });
  $(".ig").each(function () {
    $(this).click(function () {
      window.open(INSTAGRAM, "_blank");
    });
  });
  $(".yp").each(function () {
    $(this).click(function () {
      window.open(YELP, "_blank");
    });
  });
  $(".tk").each(function () {
    $(this).click(function () {
      window.open(TIKTOK, "_blank");
    });
  });
});
